var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm.$store.state.text.validation_contact.value)+" ")]),_c('v-list-item-title',{staticClass:"headline mb-3"},[_vm._v(" "+_vm._s(this.$store.state.stepCoord.gender === 'homme' ? this.$store.state.text.male_gender.value : this.$store.state.text.female_gender.value)+" "+_vm._s(this.$store.state.stepCoord.firstname)+" "+_vm._s(this.$store.state.stepCoord.lastname)+" ")]),_c('v-list-item-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(this.$store.state.text.label_validation_mail.value + ': ')}}),_vm._v(" "+_vm._s(this.$store.state.stepCoord.mail)+" ")]),(this.$store.state.stepCoord.phone)?_c('v-list-item-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(this.$store.state.text.label_validation_phone.value + ': ')}}),_vm._v(" "+_vm._s(this.$store.state.stepCoord.phone)+" ")]):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm.$store.state.text.validation_event.value)+" ")]),_c('v-list-item-title',{staticClass:"headline mb-3"},[_vm._v(" "+_vm._s(this.$store.state.stepEvent.event_name)+" ")]),_c('v-list-item-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.replaceVars(_vm.$store.state.text.validation_dates.value, {
                dateStart: new Date(
                  this.$store.state.stepEvent.date_start
                ).toLocaleDateString(_vm.$store.state.lang),
                dateEnd: new Date(
                  this.$store.state.stepEvent.date_end
                ).toLocaleDateString(_vm.$store.state.lang),
              })
            )}})]),_c('v-list-item-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$store.state.text.label_validation_event_nb_people.value)}}),_vm._v(" "+_vm._s(this.$store.state.stepEvent.event_nb_people)+" ")]),_c('v-list-item-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$store.state.text.label_validation_event_specific_needs.value
            )}}),_c('v-chip-group',{staticStyle:{"margin-left":"10px"}},_vm._l((_vm.$store.state.text.specific_needs.value),function(need,indexNeed){return _c('div',{key:indexNeed},[(_vm.$store.state.stepEvent.specific_needs[indexNeed])?_c('v-chip',[_vm._v(" "+_vm._s(need.short)+" ")]):_vm._e()],1)}),0)],1),(this.$store.state.stepEvent.customers_comment.length)?_c('v-list-item-subtitle',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$store.state.text.label_validation_comment.value)}}),_vm._v(" "+_vm._s(this.$store.state.stepEvent.customers_comment)+" ")]):_vm._e()],1)],1)],1),_vm._l((_vm.totalDays()),function(day,indexDay){return _c('v-card',{key:indexDay,staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4 text-center text-h6 greenlight--text"},[_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.replaceVars(_vm.$store.state.text.day_tab_title.value, {
                dayIndex: indexDay + 1,
                day: new Date(day).toLocaleDateString(_vm.$store.state.lang),
              })
            )}})]),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm.$store.state.text.validation_meeting_rooms_subtitle.value)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                  {
                    text:
                      _vm.$store.state.text.validation_table_header_meeting_room
                        .value,
                    value: 'name',
                  },
                  {
                    text:
                      _vm.$store.state.text
                        .validation_table_header_meeting_room_nb_people.value,
                    value: 'nb_people',
                  },
                  {
                    text:
                      _vm.$store.state.text
                        .validation_table_header_meeting_room_schedule.value,
                    value: 'display_schedule',
                  },
                  {
                    text:
                      _vm.$store.state.text
                        .validation_table_header_meeting_room_config.value,
                    value: 'selected_configuration',
                  } ],"items":_vm.getReservationByDayAndType(day, 'meeting_rooms'),"items-per-page":5,"no-data-text":"Aucune salle de réunion sélectionnée pour cette journée","hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('tr',[_c('td',[_vm._v(_vm._s(data.item.name))]),_c('td',[_vm._v(_vm._s(data.item.nb_people))]),_c('td',[_vm._v(_vm._s(data.item.display_schedule))]),_c('td',[(data.item.selected_configuration_icon)?_c('v-img',{staticClass:"mr-10",style:(data.item.disabled ? 'opacity: 0.3' : ''),attrs:{"max-height":"50","max-width":"50","dark":"","src":data.item.selected_configuration_icon,"alt":data.item.selected_configuration}}):_c('div',[_vm._v(" "+_vm._s(data.item.selected_configuration)+" ")])],1)])]}}],null,true)},[_c('template',{slot:"no-data"},[_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.$store.state.text.validation_no_meeting_room.value
                    )}})])],2)],1)],1)],1),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm.$store.state.text.validation_sleeping_rooms_subtitle.value)+" ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                  {
                    text:
                      _vm.$store.state.text.validation_table_header_room.value,
                    value: 'name',
                  },
                  {
                    text:
                      _vm.$store.state.text
                        .validation_table_header_room_single_qty.value,
                    value: 'quantity_single',
                  },
                  {
                    text:
                      _vm.$store.state.text.validation_table_header_room_twin_qty
                        .value,
                    value: 'quantity_twin',
                  } ],"items":_vm.getReservationByDayAndType(day, 'rooms'),"items-per-page":5,"no-data-text":"Aucune chambre sélectionnée pour cette journée","hide-default-footer":""}},[_c('template',{slot:"no-data"},[_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.$store.state.text.validation_no_sleeping_room.value
                    )}})])],2)],1)],1)],1),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm.$store.state.text.validation_meals_subtitle.value)+" "+_vm._s(_vm.renderMealsType())+" ")]),(
                  _vm.renderMealsType() ==
                  _vm.$store.state.text.validation_meal_carte_suffix.value
                )?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                    {
                      text:
                        _vm.$store.state.text.validation_table_header_meal.value,
                      value: 'cat_name',
                    },
                    {
                      text:
                        _vm.$store.state.text
                          .validation_table_header_meal_carte_selected.value,
                      value: 'meal_name',
                    },
                    {
                      text:
                        _vm.$store.state.text
                          .validation_table_header_meal_carte_nb_people.value,
                      value: 'quantity',
                    } ],"items":_vm.getReservationByDayAndType(day, 'meals_carte'),"items-per-page":5,"no-data-text":"Aucun repas sélectionné pour cette journée","hide-default-footer":""}},[_c('template',{slot:"no-data"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$store.state.text.validation_no_meal_carte.value
                      )}})])],2)],1):_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":[
                    {
                      text:
                        _vm.$store.state.text.validation_table_header_meal_formula
                          .value,
                      value: 'name',
                    },
                    {
                      text:
                        _vm.$store.state.text
                          .validation_table_header_meal_formula_nb_people
                          .value,
                      value: 'quantity',
                    } ],"items":_vm.getReservationByDayAndType(day, 'meals_formules'),"items-per-page":5,"no-data-text":"Aucune formule sélectionnée pour cette journée","hide-default-footer":""}},[_c('template',{slot:"no-data"},[_c('span',{domProps:{"innerHTML":_vm._s(
                        _vm.$store.state.text.validation_no_meal_formula.value
                      )}})])],2)],1)])],1)],1)],1)],1)],1)}),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"overline mb-4"},[_vm._v(" "+_vm._s(_vm.$store.state.text.validation_newsletter_subtitle.value)+" ")]),_c('v-checkbox',{staticClass:"my-n4",attrs:{"label":_vm.$store.state.text.newsletter_consent_txt.value,"color":"#E99688"},model:{value:(_vm.$store.state.newsletterConsent),callback:function ($$v) {_vm.$set(_vm.$store.state, "newsletterConsent", $$v)},expression:"$store.state.newsletterConsent"}})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }